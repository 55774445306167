<!-- 个人信息 -->
<template>
  <div class="personal-information-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '个人信息',
        txtColor: '#000',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <!-- 上传头像 -->
      <div class="header-box">
        <span class="title">头像</span>
        <div class="right-box">
          <van-uploader
            v-model="fileList"
            :max-size="2 * 1024 * 1024"
            :before-read="beforeRead"
            :after-read="afterRead"
            @oversize="onOversize"
            :max-count="1"
          >
            <van-button
              ><van-image
                round
                width=".6rem"
                height=".6rem"
                class="img-upload"
                fit="cover"
                :src="
                  userInfo && userInfo.avatarUrl
                    ? userInfo.avatarUrl
                    : defaultImg
                "
                style="margin-top: 0.1rem"
            /></van-button>
          </van-uploader>
          <van-icon name="arrow" size="14" />
        </div>
      </div>
      <!-- 基本信息 -->
      <div class="main-box">
        <div class="title-warrper">
          <div class="react"></div>
          <p class="title">
            基本信息
            <span class="remark">（必填）</span>
          </p>
        </div>
        <div class="panel-box">
          <van-form v-if="userInfo">
            <van-field v-model="userInfo.userName" label="姓名" />
            <van-field
              v-model="userInfo.phoneNumber"
              label="手机号"
              right-icon="arrow"
              @click="getPage('changePhone')"
            />
            <van-field v-model="gender" label="性别" />
            <van-field v-model="politicsStatus" label="政治面貌" />
            <van-field v-model="userInfo.entryDate" label="入职时间" />
            <van-field v-model="userInfo.staffCode" label="工号" />
            <van-field v-model="userInfo.emailAddress" label="邮箱" />
          </van-form>
        </div>
      </div>

      <!-- 个人工况 -->
      <div class="main-box">
        <div class="title-warrper">
          <div class="react"></div>
          <p class="title">个人工况</p>
        </div>
        <div class="panel-box">
          <van-form v-if="userInfo">
            <van-field v-model="userInfo.annualVacation" label="年假剩余天数" />
            <van-field
              disabled
              readonly
              label="我的荣誉"
              right-icon="arrow"
              @click="getPage('honor')"
            />
            <van-field
              disabled
              readonly
              label="我的处分"
              right-icon="arrow"
              @click="getPage('punish')"
            />
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getMyStatffInfo, uploadAvatar } from "@/api/setting/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      userInfo: null,
      fileList: [],
      defaultImg: require("@/assets/images/personal/icon_mrtx.png"),
    };
  },

  components: { TopWrapper },

  computed: {
    gender() {
      if (!this.userInfo || !this.userInfo.gender) return "";
      let obj = {
        0: "未知 ",
        1: "男 ",
        2: "女",
      };
      return obj[this.userInfo.gender];
    },
    politicsStatus() {
      if (!this.userInfo || !this.userInfo.politicsStatus) return "";
      let obj = {
        1: "群众 ",
        2: "中共党员 ",
        3: "共青团员",
        4: "其他党派 ",
        5: "无党派人士",
      };
      return obj[this.userInfo.politicsStatus];
    },
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getPage(name) {
      this.$router.push(`/${name}`);
    },
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
            if (this.userInfo && this.userInfo.avatarUrl) {
              const obj = {
                url: this.userInfo.avatarUrl,
                status: "success",
                message: "",
              };
              this.fileList = [];
              this.fileList.push(obj);
            }
          }
        }
      });
    },
    onOversize(file) {
      Toast("文件大小不能超过 2M");
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type == "image/jpeg" || file.type == "image/png") {
        return true;
      }
      Toast("请上传 jpg/png 格式图片");
      return false;
    },
    afterRead(file) {
      let params = new FormData();
      params.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadAvatar(params).then((res) => {
        Toast.clear();
        if (res) {
          let { msg, code } = res;
          if (code == 200) {
            Toast(msg);
            this.getUserInfo();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-information-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  .main-wrapper {
    margin-top: 0.3rem;
    width: 100%;
    .header-box {
      width: 100%;
      height: 1rem;
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 1rem;
      padding: 0rem 0.3rem;
      .title {
        height: 0.28rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #333333;
        line-height: 1rem;
      }
      .right-box {
        line-height: 1rem;

        ::v-deep .van-uploader__upload {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          line-height: 1rem;
        }
        ::v-deep .van-button {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          position: relative;
          border: none;
        }
        ::v-deep .van-image {
          position: absolute;

          top: 0rem;
          right: 0rem;
        }
        ::v-deep .van-uploader__wrapper {
          position: relative;
        }
        ::v-deep .van-uploader__preview {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          position: absolute;
          border: none;
          margin: 0;
          top: -0.4rem;
          right: 0.1rem;
        }
        ::v-deep .van-uploader__preview-image {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
      }
    }
    .main-box {
      margin-top: 0.4rem;
      .title-warrper {
        display: flex;
        flex-direction: row;
        line-height: 58rem;
        padding: 0 0.3rem;
        .react {
          width: 0.06rem;
          height: 0.28rem;
          background: #0094ee;
          border-radius: 0.03rem;
          margin-right: 0.12rem;
        }
        .title {
          height: 0.28rem;
          font-size: 0.3rem;
          font-weight: 500;
          color: #333333;
          line-height: 0.28rem;
          .remark {
            font-size: 0.26rem;
            color: #999999;
          }
        }
      }
      .panel-box {
        background: #fff;

        margin-top: 0.3rem;
        /deep/ .van-cell {
          line-height: 0.88rem;
          .van-cell__title {
            span {
              font-size: 0.26rem;
              font-weight: 400;
              color: #666666;
              width: 1.68rem;
            }
          }
          .van-field__control {
            text-align: right;
            font-size: 0.28rem;
            font-weight: 400;
            color: #333;
          }
        }
        .van-cell::after {
          border-bottom: 0.01rem solid #a9a9a9;
        }
      }
    }
  }
}
</style>
